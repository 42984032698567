.infobox {
  display: grid;
  grid-template-columns: auto 30px;
  align-items: center;
}
.open {
  height: auto;
  visibility: show;
}
.closed {
  height: 0;
  margin: 0;
  visibility: hidden;
}
.iconCollapse svg,
.iconExpand svg {
  transform-origin: 10px 10px;
  transition: transform 0.2s ease;
}
.iconCollapse svg {
  transform: rotate(180deg);
}
.iconExpand svg {
  transform: rotate(0deg);
}
.header {
  font-weight: 600;
  padding-right: 10px;
}

@keyframes spinUp {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes spinDown {
  100% {
    transform: rotate(0deg);
  }
}
