:root {
  --primary-color: #00bbb4;
  --secondary-color: #fbc222;
  --small-text: 0.875rem;
  --big-text: 1.187rem;
  --bigger-text: 1.8rem;
  --small-spacing: 0.34px;
  --med-spacing: 0.46px;
  --big-spacing: 0.53px;
  --mega-spacing: 0.89px;


  --toastify-color-success: var(--primary-color) !important;
  --toastify-icon-color-success: var(--primary-color) !important;
}

p {
  font-weight: 400;
}

a {
  text-decoration: none;
}

.link {
  text-decoration: underline;
  text-decoration-color: #00bbb4;
  text-decoration-thickness: 2px;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  font-size: 100%;
}

.nav {
  padding-bottom: 12px;
}

.vipps {
  display: flex;
  justify-content: center;
  padding-bottom: 5%;
  cursor: pointer;
}

.vipps img {
  width: 93%;
  object-fit: contain;
}

.container {
  width: 100vw;
  max-width: 1000px;
  min-height: 98vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#root {
  height: 100%;
}

.main {
  position: relative;
  background: linear-gradient(0deg,
      rgba(0, 144, 157, 0.49),
      rgba(0, 144, 157, 0.49)),
    url('./assets/webp/headerbilde_forside_cropped2.webp');
  background-size: cover;
  min-height: 100vh;
  height: 100%;
}

.main::after {
  z-index: -1;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-color: #00909d;
  opacity: 0.49;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}

/* mattevask */
.mattevask-amount {
  margin-top: 1.6rem;
  margin-left: 1rem;
  margin-bottom: 1.8rem;
}

.rotate45 {
  transform: rotate(45deg);
}

.rotate30 {
  transform: rotate(30deg);
}

.rotate90 {
  transform: rotate(90deg);
}

.not-included {
  opacity: 0.7;
  text-decoration: line-through;
}

.radio-mattevask label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.7rem;
  margin-right: 1.375rem;
  margin-bottom: 1.7rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: solid 2px var(--primary-color);
  color: #fff;
  font-size: 2.5rem;
  text-align: center;
  background-color: var(--primary-color);
}

.radio-mattevask label:last-of-type {
  margin-right: 0px;
}

.radio-mattevask input[type='radio']:checked+label {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* valuecard */
.valuecardContainer {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 2.5px #00000029;
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  background-color: var(--primary-color);
}

.radio-valuecard {
  width: 93%;
  margin-bottom: 2.9rem;
}

.radio-valuecard .radio-valuecard-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.125rem;
  height: 2.125rem;
  margin-left: 1.05rem;
  margin-right: 1.5rem;
  border-radius: 50%;
  border: solid 2px #fff;
  transition: all 0.1s;
  font-size: 1.375rem;
  text-align: center;
}

.radio-valuecard-icon .radio-valuecard-icon-inner {
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
}

.radio-valuecard input[type='radio']:checked+label .radio-valuecard-icon-inner {
  background-color: var(--secondary-color);
}

.valuecard-discount-icon {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #25221e;
  color: var(--secondary-color);
  transform: rotate(-10deg);
  margin-left: auto;
  margin-right: 1rem;
}

.valuecard-discount-icon p {
  font-weight: 700;
  font-size: 1.437rem;
  letter-spacing: 0.55px;
  margin: 0px;
}

.valuecard-discount-icon p:last-of-type {
  font-size: 0.687rem;
  letter-spacing: 0.26px;
  font-weight: 400;
  margin: 0px;
  color: #fff;
}

/* selvvask */
.discount-circle {
  background-color: #25221e;
  height: 5.9rem;
  width: 5.9rem;
  border-radius: 50%;
  border: 2px solid #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(-10deg);
}

.discount-circle p {
  font-size: 0.875rem;
  color: #fff;
  margin: 0px;
  padding-left: 0px !important;
  letter-spacing: 0.34px;
}

.discount-circle p span {
  font-size: 1.812rem;
  font-weight: 700;
  display: inline-block;
  transform: translate(3px, 0px);
  color: #f7c851;
  letter-spacing: 0.7px;
}

.icon-text {
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding-left: 2rem;

  width: 80%;
}

.icon-text p {
  padding-left: 1.375rem;
}

.big-icon-text {
  display: flex;
  padding-left: 2rem;
  align-items: center;
  align-self: flex-start;
  width: 70%;
  margin-bottom: 1rem;
}

.big-icon-text svg {
  width: 3.125rem;
  height: 2.937rem;
  flex-grow: 1;
}

.big-icon-text p {
  display: inline-block;
  padding-left: 1.1rem;
  margin: 0px;
}

.divein-logo {
  margin-top: 20px;
}

.divein-logo-small {
  padding: 2.562rem 0rem 2.562rem 0rem;
}

.card {
  border: 1px solid #c6c6c6;
  box-shadow: 0px 0px 2px #c6c6c6;
  height: 7.18rem;
  width: 93%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.card .grid {
  display: flex;
  height: 2.625rem;
  justify-content: space-between;
  align-items: center;
}

.card p {
  margin-top: 0.875rem;
  margin-bottom: 0.85rem;
}

.card .grid span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.circle-one {
  margin-left: 2.25rem;
}

.circle-two {
  margin-right: 2.25rem;
}

.circle-one,
.circle-two {
  height: 2.625rem;
  width: 2.625rem;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  align-self: center;
  justify-self: center;
  font-size: 2.313rem;
  color: var(--primary-color);
}

.amount-text {
  font-size: 2.313rem;
  margin: 0px;
  color: var(--secondary-color);
}

/* sub */
.subscription-info-text {
  padding-top: 2.14rem;
  padding-bottom: 2.5rem;
}

.subscription-content label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.subscription-content label label span {
  margin: 0px !important;
  margin-right: 1.162rem !important;
}

.subscription-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.subscription-divein-logo {
  margin-top: 10rem;
  margin-bottom: 2.25rem;
}

.subscription-price {
  padding-bottom: 1.75rem;
}

.subscription-content p:first-of-type {
  text-align: center;
  font-size: 1.47rem;
  color: black;
}

.subscription-content p {
  color: black;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.item {
  padding: 0px 1.562rem;
}

/* vaskegate singelvask */

.selectionHeader {
  display: inline-grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 2.187rem;
  width: 93%;
}

.danger-yellow {
  position: relative;
  border-left: 1.2rem solid transparent;
  border-right: 1.2rem solid transparent;
  border-bottom: 2.3rem solid #ff6700;
  margin-left: 10%;
}

.danger-yellow::after {
  content: '!';
  position: absolute;
  top: 0.5rem;
  left: -0.2rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  height: 0.99em;
  width: 1.077em;
}

.programHeader {
  grid-column: 2;
  justify-self: center;
  font-size: 1.437rem;
  font-weight: 700;
}

.primary {
  color: var(--primary-color);
}

.price {
  grid-column: 3;
  justify-self: end;
  font-weight: 900;
  font-size: var(--big-text);
  padding-right: 1.375em;
}

.inner {
  width: 90%;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  background: #fff;
  width: 93%;
  cursor: pointer;
  box-shadow: 0px 0px 2.5px #00000029;
  border: 0.6px solid #c6c6c6;
  padding-top: 20px;
  /* margin-top: 1rem; */
  padding-bottom: 20px;
}

.outer-checkbox {
  display: flex;
  align-items: center;
  width: 93%;
  margin-top: 1rem;
}

.iconBox {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-right: 5px;
}

textarea,
input {
  width: 100%;
  display: block;
}

.radio-selfWash {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

input[type='radio'] {
  display: none;
}

.radio-selfWash label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
  margin-bottom: 5%;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  border: solid 2px var(--primary-color);
  color: white;
  font-size: 1.375rem;
  text-align: center;
  background-color: var(--primary-color);
}

.radio-selfWash label:nth-child(10n + 10) {
  margin-right: 0px;
}

.radio-selfWash input[type='radio']:checked+label {
  background-color: var(--secondary-color);
  border: solid 2px var(--secondary-color);
}

input[type='text'],
textarea {
  font-size: var(--big-text);
  margin-top: 2.412rem;
  padding-top: 1.412rem;
  padding-bottom: 1.162rem;
  margin-bottom: 2.162rem;
  padding-left: 1.912rem;
  border: 0.081em solid var(--primary-color);
  height: 3.43rem;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

input[type='text']::placeholder {
  color: rgb(167, 164, 164);
}

input[type='text']:active,
input[type='text']:focus {
  border-color: #00bbb4;
  border: 0.081em solid var(--primary-color);
  padding-left: 1.912rem;
  outline: none !important;
  box-sizing: border-box;
}

/* #bilnummer {
  padding-top: 2.412em;
} */

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']+label span {
  display: inline-block;
  height: 2.125rem;
  width: 2.125rem;
  transition: all 0.1s;
  background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cdefs%3E%3Cstyle%3E.a,.c%7Bfill:none;%7D.a%7Bstroke:%2300bbb4;stroke-width:2px;%7D.b%7Bstroke:none;%7D%3C/style%3E%3C/defs%3E%3Cg class='a'%3E%3Ccircle class='b' cx='17' cy='17' r='17'/%3E%3Ccircle class='c' cx='17' cy='17' r='16'/%3E%3C/g%3E%3C/svg%3E");
  margin-left: 1.06rem;
}

input[type='checkbox']:checked+label span {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%2300bbb4;%7D.b%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-32 -156)'%3E%3Ccircle class='a' cx='17' cy='17' r='17' transform='translate(32 156)'/%3E%3Cpath class='b' d='M1394,147.142l3.467,5.027,8.09-11.127' transform='translate(-1350.667 27.189)'/%3E%3C/g%3E%3C/svg%3E");
}

.svgLabel {
  cursor: pointer;
}

.labelText {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  justify-content: center;
}

.inputLabel {
  color: black;
  font-weight: 500;
  font-size: var(--big-text);
}

.priceLabel,
.subLabel {
  display: inline-block;
}

.subLabel {
  color: #050000;
  padding-top: 5.3px;
  margin: 0;
  font-size: 0.75rem;
}

.priceLabel {
  align-self: center;
  margin: 0px 0px 0px auto;
  font-weight: bold;
  padding-right: 1.375rem;
}

.price-text {
  padding-top: 5%;
  font-size: var(--big-text);
  padding-bottom: 5%;
  margin-left: 1rem;
}

.price-text p {
  margin: 5px 0 5px 0;
}

.button {
  width: 93%;
  height: 4.2rem;
  cursor: pointer;
  box-shadow: #00000029 0px 0px 2px;
  display: flex;
  background-color: var(--primary-color);
  justify-content: space-between;
  margin-bottom: 1.33rem;
  align-items: center;
  color: #ffffff;
}

.nav-button {
  margin: 0px !important;
  padding: 0px !important;
}

.nav-button p {
  font-weight: 600 !important;
}

.nav-box {
  margin-top: 60px;
  width: 85%;
}

.nav-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.nav-iconbox {
  align-items: center;
  background: #fcb222;
  box-shadow: #00000029 0px 0px 2px;
  display: flex;
  justify-content: center;
  height: 4.2rem;
  width: 3.5rem;
}

.nav-button svg {
  margin-right: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center p {
  font-size: 1.5rem !important;
  letter-spacing: 0.83px;
}

.button p {
  margin: 0px;
  font-size: var(--big-text);
  letter-spacing: var(--med-spacing);
  padding-left: 1.91rem;
  display: inline;
  font-weight: 500;
}

.big-button {
  height: auto;
}

.big-button-content {
  display: grid;
  width: 100%;
  grid-template-columns: 5.9rem auto 1fr;
  grid-template-rows: auto auto;

  padding: 2rem 0rem 1.187rem 1.262rem;
}

.big-button-content-textbox {
  padding-left: 1rem;
}

.big-button-content-textbox p {
  padding-left: 0px;
  font-weight: 300;
}

.big-button-content span {
  align-self: end;
  justify-self: end;
}

.black-circle,
.small-black-circle {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #25221e;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: span 2;
}

.small-black-circle p {
  font-size: 1.5rem;
  font-weight: 600;

}

.black-circle p {
  font-size: 1.8rem;
  font-weight: 600;
}


.forward-arrow {
  padding-right: 1.162rem;
}

.forward-arrow-nav {
  padding-right: 8px;
}

.firm-icon {
  padding-left: 1.91rem;
}

.vipps-logo {
  padding-left: 5%;
}

.nopad {
  padding-left: 0.981rem !important;
}

.firmakunde {
  display: flex;
  align-content: center;
  width: 60%;
  cursor: pointer;
  margin-top: 3.125rem;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.firmakunde p {
  display: inline;
  padding-left: 0.915rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.firmakunde span svg {
  align-self: center;
}

.forward-arrow-icon {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-2 -2)'%3E%3Cpath class='a' d='M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z'/%3E%3Cpath class='a' d='M18,24l6-6-6-6'/%3E%3Cpath class='a' d='M12,18H24'/%3E%3C/g%3E%3C/svg%3E");
  display: inline-block;
}

/* templates? */
.triangle {
  transform: translate(10vw, 2.3rem);
  display: inline-block;
  height: 0px;
  width: 0px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.relative {
  position: relative;
}

.tooltip {
  max-width: 60%;
  z-index: 2;
  right: 2rem;
  pointer-events: none;
}

.tooltip span {
  font-size: var(--small-text);
}

.grid {
  display: grid;
}

.column-two {
  grid-template-columns: 1fr 1fr;
}

.invalid {
  border-color: tomato !important;
}

.one {
  grid-column: 1;
  padding-left: 1.8em;
}

.two {
  grid-column: 2;
  padding-right: 1.3em;
  justify-self: end;
}

.big {
  width: 93%;
}

.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700 !important;
}

.extrabold {
  font-weight: 800;
}

.med-spacing {
  letter-spacing: var(--med-spacing) !important;
}

.small-spacing {
  letter-spacing: var(--small-spacing) !important;
}

.big-spacing {
  letter-spacing: var(--big-spacing) !important;
}

.mega-spacing {
  letter-spacing: var(--mega-spacing) !important;
}

.dark {
  color: #535353;
}

.color-accent {
  color: var(--secondary-color) !important;
}

.mega-text {
  font-size: 2.31rem !important;
}

.big-text {
  font-size: var(--big-text) !important;
}

.bigger-text {
  font-size: var(--bigger-text) !important;
}

.small-text {
  font-size: var(--small-text) !important;
}

.center-text {
  text-align: center;
}

.column-end {
  margin-top: auto;
}

.text-box {
  width: 85vw;
}

.block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
}

.less-width {
  max-width: 80%;
}

.bubbles {
  background-image: url('./assets/webp/Blue-Water-Drops.webp');
  background-size: auto;
  background-position: 50% 137%;
}

.bubbles-small {
  background-image: url('./assets/webp/Blue-Water-Drops.webp');
  background-size: auto;
  background-position: 50% 17%;
}

.notAvailable {
  font-size: small !important;
  display: block !important;
}

@media screen and (max-width: 340px) {
  .radio-selfWash label {
    margin-bottom: 2%;
    margin-right: 2%;
  }

  .button p {
    padding-left: 1rem;
  }

  .divein-logo {
    margin-top: 0px;
  }
}