a {
  color: inherit;
  text-decoration: none;
}
textarea {
  height: auto !important;
}
input,
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
textarea:focus {
  border-radius: 0px;
  -webkit-border-radius: 0px;
}
.header {
  font-size: 1.2rem;
  color: #535353;
  letter-spacing: 0.85px;
  font-weight: 500;
  max-width: 80%;
}
.inputBox input[type='text'] {
  margin: 0;
  border-color: hsl(178, 100%, 37%);
  padding-bottom: 10px;
}
.inputBox input[type='text'],
.inputBox input[type='text']:focus {
  border-width: 1px;
}
.inputBox input[type='text']:focus {
  border-color: #fbc222;
}
input[type='button'] {
  border: none;
}
label {
  font-size: 1.2rem;
  color: #535353;
  letter-spacing: 0.53px;
}
.inputBox {
  width: 80%;
  padding-bottom: 10px;
  position: relative;
  display: flex;
}
.floatingLabel {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  color: #767676;
  transition: 0.2s ease all;
}
.inputBox input[type='text']:focus ~ .floatingLabel,
textarea ~ .floatingLabel,
.inputBox input[type='text']:not(:focus):valid ~ .floatingLabel,
textarea:not(:focus):valid ~ .floatingLabel {
  top: 6px;
  bottom: 10px;
  left: 20px;
  font-size: 11px;
  color: #252525;
  opacity: 1;
}
textarea {
  padding: 10px;
}
.smallButton {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: hsl(178, 100%, 37%);
  color: #fff;
  width: 100px;
  padding-left: 10px;
}
.item {
  width: 50%;
  display: flex;
  padding: 6px;
  margin: 5px 0 5px 0;
  justify-content: space-between;
}
.infoBox {
  width: 80%;
}
.noform {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
input[type='submit'] {
  border: none;
  padding-left: 40px;
  font-size: 1.3rem;
}
.button {
  width: 85%;
  height: 4.2rem;
  cursor: pointer;
  box-shadow: #00000029 0px 0px 2px;
  display: flex;
  background-color: var(--primary-color);
  justify-content: space-between;
  margin-bottom: 1.33rem;
  align-items: center;
  color: #ffffff;
  font-size: 1.5rem;
  padding-left: 1.5rem;
}
