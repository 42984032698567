.container {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 60vh;
}
.header {
  font-size: 1.5rem;
  letter-spacing: 0.58px;
  color: #535353;
}
.userinfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
}
.userinfo p {
  margin: 3px 0px 3px 0px;
}
.userinfo *:nth-child(2n + 2) {
  grid-column: span 2/ span 2;
  justify-self: end;
}

.button {
  display: flex;
  height: 3.4rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background-color: #00bbb4;
  color: #fff;
  /* border-radius: 5px; */
}
.button p {
  padding: 0rem 1.25rem 0rem 1.25rem;
  font-weight: 500;
}
.discount p {
  margin-bottom: 0rem;
}
.diveinlogo {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.centerText {
  text-align: center;
  padding-top: 0.5rem;
}
