.header {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  height: 3.8rem;
  box-sizing: border-box;
  background-color: var(--primary-color);
  max-width: 1000px;
}
.profileIcon {
  grid-column: 3;
  margin-right: 10px;
}
.headerText {
  font-size: 1.375rem;
  justify-self: center;
  margin: 0;
  letter-spacing: var(--big-spacing);
  color: #fff;
}
.backbutton {
  padding-left: 2.375em;
}
.login {
  justify-self: end;
  margin-right: 3px;
  color: #fff;
}
.profileLink {
  display: flex;
  align-items: center;
  justify-self: end;
  grid-column: 3;
}
.backButton {
  margin-right: auto;
  padding-top: 1.4em;
  grid-column: 1;
}
