.diveInLogo {
  padding-bottom: 32px;
  padding-top: 32px;
}

@media screen and (max-width: 340px) {
  .diveInLogo {
    padding: 12px;
  }
}
