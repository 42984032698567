.blandHalfcircleTop {
  background-color: #00bbb4 !important;
  color: #fff !important;
}
.blandBorder {
  border-color: #00bbb4 !important;
}
.greyBanner {
  background-color: #bebebe;
  height: 9rem;

  margin-left: -2rem;
  margin-right: -2rem;
  width: 92vw;
  display: flex;

  position: relative;
}
.greyBanner::before {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 3.55vw solid #757575;
  border-left: 3.55vw solid transparent;
  border-right: 3.55vw solid transparent;
  transform: translate(0.3vw, -85%) rotate(-45deg);
}
.greyBanner::after {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 3.55vw solid #757575;
  border-left: 3.55vw solid transparent;
  border-right: 3.55vw solid transparent;
  transform: translate(-0.2vw, -85%) rotate(45deg);
}
.programHalfcircleTop {
  background-color: #fbc222;
  height: 3rem;
  width: 6rem;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 1.625rem;
  color: black;
  transform: translate(0px, -3rem);
}
.programHalfcircleTop p {
  margin-top: 0.5rem;
  font-weight: 600;
}
.programContainer {
  border: 5px solid #fbc222;
  /* border-radius: 8px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 82vw;
  position: relative;
  box-sizing: border-box;
  margin-top: 3rem;
  box-shadow: 0px 0px 3rem #ccc;
  margin-bottom: 1.5rem;
  background-color: #fff;
}
.programContainer:first-of-type {
  margin-top: 4rem;
}
.programHeader {
  font-size: 2rem;
  font-weight: 700;
  border-bottom: 1px solid #fbc222;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  margin-top: -1.5rem;
}
.programInfoText {
  text-align: center;
  margin-bottom: 1rem;
}
.programInfoText p {
  margin-top: 5px;
  font-weight: 600;
  margin-bottom: 5px;
}
.programBanner {
  background-color: #00bbb4;
  height: 9rem;
  margin-left: -2rem;
  margin-right: -2rem;
  width: 92vw;
  display: flex;
  position: relative;
}
.programBanner::before {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 3.55vw solid #077b77;
  border-left: 3.55vw solid transparent;
  border-right: 3.55vw solid transparent;
  transform: translate(0.3vw, -85%) rotate(-45deg);
}
.programBanner::after {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 3.55vw solid #077b77;
  border-left: 3.55vw solid transparent;
  border-right: 3.55vw solid transparent;
  transform: translate(-0.2vw, -85%) rotate(45deg);
}
.programBannerHeader {
  font-size: 1.25rem !important;
  font-weight: 700;
  color: #fff;
  margin-top: 1rem !important;
}
.programBannerLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 5%;
  color: #fff;
}
.programBannerLeft p {
  font-size: 0.85rem;
  margin: 0.2rem 0rem 0.2rem 0rem;
}
.programBannerLeft p span {
  margin-right: 1rem !important;
  font-weight: 600;
}
.programButton {
  width: 8rem;
  height: 2.5rem;
  background-color: #fbc222;
  display: flex;
  align-items: center;
  margin-top: 3.1rem;

  margin-bottom: 1rem;
  justify-content: center;
  color: #1a1a19;
}
.programButton p {
  font-size: 1rem;
  font-weight: 600;
}
.notIncluded {
  opacity: 0.1;
  text-decoration: line-through;
}
.programBannerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-right: 5%;
}
.programBannerRightCircle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5.562rem;
  height: 5.562rem;
  border-radius: 50%;
  background-color: black;
  color: #fff;
}
.programBannerRightCircle p {
  margin: 0px;
  font-size: 0.75rem;
}
.programBannerRightCircle p:first-of-type {
  font-size: 1.562rem;
  font-weight: 700;
}
.programBottom {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.programBottom p {
  margin: 0;
  font-weight: 600;
}
/* @media screen and (max-width: 340px) {
  .programBanner::after,
  .greyBanner::after {
    transform: translate(-3vw, -85%) rotate(45deg);
  }
} */
@media screen and (min-width: 1000px) {
  .programContainer,
  .programBanner,
  .greyBanner {
    width: 1000px;
  }
  .programBanner::before,
  .programBanner::after,
  .greyBanner::before,
  .greyBanner::after {
    display: none;
  }
}
