@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'proxima-nova';
}
body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url('./assets/webp/Blue-Water-Drops.webp')
    url('./assets/webp/headerbilde_forside_cropped2.webp');
}

html {
  scroll-behavior: smooth;
}
